<template>
  <div class="overflow-x-hidden">
    <div class="flex gap-4 py-16 xl:py-24 translate-x-12  xl:translate-x-36">
      <div v-for="image in images" class="rounded-xl overflow-hidden">
        <img :src="image.sourceUrl" :alt="image.altText">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps(["data"]);
const images = ref(props.data.images);
</script>

<style scoped>

</style>
