<template>
  <div class="px-8 md:px-12 lg:px-16 xl:px-24 py-12">
    <div class="page-container">
      <div class="md:w-7/12">
        <h2 class="text-secondary">{{text}}</h2>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps(["data"]);

const text = ref(props.data.text);
</script>

<style scoped>
</style>
