<template>
  <div class="bg-gradient-to-b from-white via-primary/10 to-white px-4 md:px-12 xl:px-24 mb-8 md:mb-16 xl:mb-24 py-12">
    <div class="page-container">
      <div class="flex flex-col sm:flex-row gap-6">
        <div v-for="(pageLink, index) in pageLinks" class="w-full xl:w-6/12 drop-shadow-lg mb-4">
          <div class="flex flex-col md:flex-row px-5 py-4 rounded-xl gap-4 min-h-[318px]" :class="index % 2 !== 0 ? 'bg-primary text-white' : 'bg-white'">
            <div class="xl:w-3/5 flex flex-col order-2 md:order-none gap-2 pl-2 pt-4 items-start justify-end">
              <h3 class="text-2xl font-medium">{{ pageLink.title }}</h3>
              <p class="!text-sm max-w-[85%] !mb-4">{{ pageLink.pageMeta.pageNavLinkDescription }}</p>
              <PageLinkButton theme="grey" :link-object="{path: pageLink.uri}" class="mb-4">
                Read more
              </PageLinkButton>
            </div>
            <NuxtLink :to="{path: pageLink.uri}" class="rounded-md overflow-hidden w-full xl:w-2/5">
              <img loading="lazy" v-if="pageLink?.featuredImage?.node"
                   :src="pageLink.featuredImage.node.sourceUrl"
                   :alt="pageLink.featuredImage.node.altText"
                   class="object-cover min-h-full" />
              <img v-else loading="lazy" alt="" src="~/assets/img/placeholder.jpg"
                   class="object-cover min-h-full" />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageLinkButton from "~/components/global/Buttons/PageLinkButton.vue";

const props = defineProps(["data"]);

const pageLinks = ref(props.data.pageLinks);

</script>

<style scoped>

</style>
