export function getContactFormQuery(id: string) {
  return gql`
query getContactForm {
   getContactForm(id: "${id}") {
    formName
    formFields {
      labels
      name
      options
      required
      type
    }
  }
}
`
}
