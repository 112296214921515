<template>
  <div class="py-12 px-8 md:px-12 lg:px-16 xl:px-24">
    <div class="page-container">
      <div class="flex flex-wrap justify-between headline-text-block" v-if="headline || text">
        <div v-html="getFormattedHeadline(<string>headline)" v-if="headline" :class="layout === '2 Columns' ? 'md:w-1/3' : 'md:w-7/12'"></div>
        <div v-html="text" v-if="text" :class="layout === '2 Columns' ? 'md:w-7/12' : 'md:w-7/12'"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const {getFormattedHeadline} = usePage();

const props = defineProps(['data']);
const headline = ref(props.data.headline);
const text = ref(props.data.text);
const layout = ref(props.data.layout);
</script>

<style scoped>

</style>
