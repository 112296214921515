<template>
  <div
    class="bg-gradient-to-b from-white via-primary/10 to-white px-8 md:px-12 lg:px-16 xl:px-24 mb-8 md:mb-16 xl:mb-24 py-12 overflow-x-hidden relative">
    <div class="page-container relative transition duration-150 ease-in"
         :class="hasSliderInitiated ? 'visible opacity-1' : 'invisible opacity-0'">
      <Carousel :wrap-around="false"
                :breakpoints="breakpoints"
                ref="carousel"
                class="page-links-slider"
                @init="handleInit"
      >
        <Slide v-for="(pageLink, index) in pageLinks" :key="index">
          <div class="w-full rounded-xl drop-shadow-md overflow-hidden">
            <div class="bg-white w-full px-5 py-4">
              <h3 class="!text-xl !mb-0 font-medium">
                <NuxtLink :to="pageLink.uri" class="line-clamp-3" v-html="pageLink.pageMeta.pageSliderHeadline" />
              </h3>
            </div>
            <div class="h-0 relative overflow-hidden pb-[50%]">
              <NuxtLink :to="pageLink.uri">
                <img v-if="pageLink.featuredImage?.node.sourceUrl"
                     :src="pageLink.featuredImage?.node.sourceUrl"
                     :alt="pageLink.featuredImage?.node.altText"
                     loading="lazy"
                     class="absolute inset-0 w-full h-full object-cover"
                />
                <img v-else src="~/assets/img/placeholder.jpg" loading="lazy"
                     class="absolute inset-0 w-full h-full object-cover" alt="">
              </NuxtLink>
            </div>
          </div>
        </Slide>
      </Carousel>
      <div class="flex gap-2 absolute top-[50%] -translate-y-[50%] right-0 left-0 justify-between">
        <button @click="handleSliderNav('prev')"
                class="relative -left-2.5 rounded-full w-8 h-8 bg-secondary text-white flex items-center justify-center">
          <ArrowLeftIcon class="w-[50%]" />
        </button>
        <button @click="handleSliderNav('next')"
                class="relative -right-2.5 rounded-full w-8 h-8 bg-secondary text-white flex items-center justify-center">
          <ArrowRightIcon class="w-[50%]" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Carousel, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import ArrowLeftIcon from "~/components/global/Icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "~/components/global/Icons/ArrowRightIcon.vue";
import { ref } from "vue";

const props = defineProps(["data"]);

const carousel = ref(null);

const sliderInitiated = ref(false);

const pageLinks = ref(props.data.pageLinks);

const breakpoints = ref({
  700: {
    itemsToShow: 2,
    snapAlign: "start"
  },
  1024: {
    itemsToShow: 3,
    itemsToScroll: 3,
    snapAlign: "start"
  }
})

const handleSliderNav = (dir:string) => {
  if (dir === 'next') {
    carousel.value.next();
  } else if (dir == 'prev') {
    carousel.value.prev();
  }
}

const handleInit = () => {
  sliderInitiated.value = true;
};

const hasSliderInitiated = computed(() => {
  return sliderInitiated.value;
});

</script>

<style scoped>
.carousel {
  text-align: left;
}

.carousel__slide {
  padding: 0 5px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0.5;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide--visible {
  opacity: 1;
}
</style>
