<template>
  <blockquote class="flex justify-center items-center text-center px-4 md:px-12 xl:px-24 pt-2 pb-4 md:mb-24">
    <span class="text-2xl">"{{ quote }}"
      <cite class="text-lg block mt-4">— {{author}}</cite>
    </span>
  </blockquote>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const props = defineProps(["data"]);

const CACHE_KEY = 'cachedData';

const fetchData = async () => {
  const response = await fetch("https://api.quotable.io/random");
  const data = await response.json();

  if (response.ok) {
    localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: Date.now() }));
    return data;
  } else {
    console.error('Failed to fetch data.');
    return null;
  }
};

const isCacheValid = (cachedData) => {
  const { data, timestamp } = cachedData;
  const currentTime = Date.now();
  const cacheTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

  return currentTime - timestamp < cacheTime;
};

const quote = ref('');
const author = ref('');

onMounted(async () => {
  const cachedData = localStorage.getItem(CACHE_KEY);

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    if (isCacheValid(parsedData)) {
      author.value = parsedData.data.author;
      quote.value = parsedData.data.content;
    } else {
      const newData = await fetchData();
      if (newData) {
        author.value = newData.author;
        quote.value = newData.content;
      }
    }
  } else {
    const newData = await fetchData();
    if (newData) {
      author.value = newData.author;
      quote.value = newData.content;
    }
  }
});
</script>

<style scoped>
/* Your CSS styles here */
</style>
