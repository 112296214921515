<template>
  <div class="px-8 md:px-12 lg:px-16 xl:px-24 py-12">
    <Search class="w-full"
            label-classes="hidden"
            input-group-classes="md:!w-full !mb-14 relative"
            placeholder="Search FAQs"
            input-classes="!w-full !bg-transparent !rounded-md !border !border-primary/20 !px-6 !py-6"
            submit-btn-label=""
            submit-btn-classes="!bg-transparent !border-none !shadow-none absolute right-0 bottom-0 top-0"
            :show-search-icon="true"
            @search="handleSearch"
    />
    <div v-if="faqsData.length > 0">
      <div v-for="faqCategory in faqsData" class="mb-12">
        <h3 class="font-semibold">{{ faqCategory.name }}</h3>
        <ul class="mt-8">
          <li v-for="faq in faqCategory.faqs" :key="faq.id" class="!mb-4 rounded-xl -mx-4"
              :class="isOpen(faq.id) ? 'bg-secondary/10' : ''">
          <span class="px-4 pt-3 inline-block">
            <button @click="toggleAccordion(faq.id)"
                    :aria-expanded="isOpen"
                    :aria-controls="`collapse${faq.id}`"
                    class="flex gap-2 justify-between items-center"
            ><span class="text-grey-300 text-lg text-left"
                   :class="isOpen(faq.id) ? 'text-secondary font-semibold' : ''">{{ faq.title }}</span>
              <svg
                class="w-3 transition-all duration-200 transform text-secondary"
                :class="{
          'rotate-180': isOpen(faq.id),
          'rotate-0': !isOpen(faq.id),
        }"
                fill="none"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 10"
                aria-hidden="true"
              >
                <path
                  d="M15 1.2l-7 7-7-7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <span v-if="isOpen(faq.id)" :id="`collapse${faq.id}`" class="inline-block pt-2 pb-4 faq-content"
                  v-html="faq.content">
            </span>
          </span>
          </li>
        </ul>
      </div>
    </div>
    <p v-else>
      No results found.
      <span v-if="route.query.q && route.query.q !== ''">Please try a different search.</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { getFAQsQuery } from "~/graphql/queries/getFAQs";

const route = useRoute();
const query = getFAQsQuery();
const { data } = await useAsyncQuery(query);

const faqsData = ref([]);

const activeAccordionId = ref(null);

let faqs = [];
data.value.fAQs.nodes.forEach((item) => {
  let faq = {
    id: item.id,
    title: item.title,
    content: item.content
  };

  let itemCategory = item.categories.nodes[0];

  let foundCategoryIndex = faqs.findIndex((category) => category.name === itemCategory.name);

  if (foundCategoryIndex === -1) {
    let category = {
      name: itemCategory.name,
      faqs: [faq]
    };
    faqs.push(category);
  } else {
    faqs[foundCategoryIndex].faqs.push(faq);
  }
});

faqsData.value = faqs;


const isOpen = (id: string) => {
  return activeAccordionId.value === id;
};

const toggleAccordion = (id: string) => {
  if (isOpen(id)) {
    activeAccordionId.value = null;
  } else {
    activeAccordionId.value = id;
  }
};



const handleSearch = (search: string) => {
  if (search !== '') {
    faqsData.value = faqs.filter((faqCategory) => {
      const filteredFaqs = faqCategory.faqs.filter(
        (faq) =>
          (faq.title && faq.title.toLowerCase().includes(search.toLowerCase())) ||
          (faq.content && faq.content.toLowerCase().includes(search.toLowerCase()))
      );

      if (
        filteredFaqs.length > 0 ||
        faqCategory.name.toLowerCase().includes(search.toLowerCase())
      ) {
        return { name: faqCategory.name, faqs: filteredFaqs };
      }

      return null;
    });
  } else {
    faqsData.value = faqs;
  }
};
</script>

<style scoped>

</style>
