import * as yup from "yup";
import { useForm } from "vee-validate";

export function useContactForm() {
  const getInitialValues: ContactFormValues = {
      'first-name': '',
      'last-name': '',
      'your-message': '',
      'your-email': '',
    }

  const getValidationSchema = yup.object({
    'first-name': yup.string().required().label('First Name'),
    'last-name': yup.string().required().label('Last Name'),
    'your-message': yup.string().required().label('Message'),
    'your-email': yup.string().email().required('Please enter a valid email')
  });

  const { values, errors, handleSubmit, setFieldValue} = useForm({
    initialValues: getInitialValues,
    validationSchema: getValidationSchema
  });

  return {
    values,
    errors,
    setFieldValue,
    handleSubmit
  }
}
