<template>
  <div class="px-8 md:px-12 lg:px-16 xl:px-24 py-12 bg-primary/10">
    <div class="page-container">
      <div class="flex flex-wrap justify-between py-6">
        <h2>{{ title }}</h2>
        <p class="w-full md:w-4/12">{{lead}}</p>
      </div>
      <div v-if="teamMembers.length > 0" class="md:-mx-12 ">
        <div v-for="teamMember in teamMembers" class="w-full md:w-3/12 px-0 md:px-12 inline-block align-top mb-4">

          <div class="mb-4 border border-primary/20 rounded-xl overflow-hidden">
            <img v-if="teamMember?.node?.featuredImage?.node" loading="lazy" :src="teamMember.node.featuredImage.node.sourceUrl"
                 :srcset="teamMember.node.featuredImage.node.srcSet"
                 :sizes="teamMember.node.featuredImage.node.sizes"
                 :alt="teamMember.node.featuredImage.node.altText" />
            <img v-else alt="" src="~/assets/img/profile-picture-placeholder.jpg" />
          </div>

          <h3 class="!mb-0.5 !text-lg">{{teamMember.node.title}}</h3>
          <p class="text-sm">{{teamMember.node.teamMeta.role}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getTeamQuery } from "~/graphql/queries/getTeam";

const props = defineProps(["data"]);

const title = ref(props.data.title);
const lead = ref(props.data.lead);
const teamMembers = ref([]);

const query = getTeamQuery();
const { data } = await useAsyncQuery(query);

if (data.value?.team?.edges) {
  teamMembers.value = data.value.team.edges
}

</script>

<style scoped>
</style>
