export function getFAQsQuery() {
  return gql`
query getFAQs {
   fAQs(first: 100, where: {status: PUBLISH}) {
  nodes {
    categories {
      nodes {
        categoryId
        name
      }
    }
    id
    content
    title
  }
}
}
`;
}

