<template>
  <div class="page-section mb-8 lg:mb-20 xl:mb-64 lg:px-16 xl:px-24 last:pb-64y">
    <div class="page-container">
      <h3 class="text-center">{{ headline }}</h3>

      <div class="relative">
        <div v-if="slideInitiated" class="flex z-[14] gap-2 px-24 absolute right-0 left-0 justify-between top-[50%] -translate-y-[50%]">
          <button @click="carousel.prev()" v-if="shouldShowArrow()" class="rounded-full w-6 h-6 bg-grey flex items-center justify-center">
            <ArrowLeftIcon class="w-[50%]"/>
          </button>
          <button @click="carousel.next()" v-if="shouldShowArrow()"
                  class="rounded-full w-6 h-6 bg-grey flex items-center justify-center"
          >
            <ArrowRightIcon class="w-[50%]"/>
          </button>
        </div>

        <Carousel @init="handleInit"
                  :wrap-around="false"
                  :breakpoints="breakpoints"
                  ref="carousel"
                  class="mockup-showcase-slider mt-12"
        >
          <Slide v-for="(mockup, index) in mockupShowcase" :key="index">
            <div class="text-center">
              <div class="text-grey-300 px-16">
                {{ mockup.text }}
              </div>
              <div class="line h-[32px] w-[1px] my-4 mx-auto bg-grey-200"></div>
              <div>
                <div class="relative mx-auto flex justify-center w-72">
                  <div class="w-[calc(100%-10%)] overflow-hidden absolute top-[3%] left-[5%] rounded-[2.5rem] z-[11]">
                    <img v-if="mockup.mockupImage"
                         :srcset="mockup.mockupImage.srcSet"
                         :alt="mockup.mockupImage.altText"
                         :src="mockup.mockupImage.sourceUrl"
                         class="z-[11]"
                    />
                  </div>
                  <img class="relative z-10 w-full" src="~/assets/img/homepage-hero-bg-phone.png" loading="lazy" alt="" />
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Pagination/>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Carousel, Pagination, Slide } from "vue3-carousel";
import {onBeforeUnmount, onMounted, ref} from "vue";
import ArrowRightIcon from "~/components/global/Icons/ArrowRightIcon.vue";
import ArrowLeftIcon from "~/components/global/Icons/ArrowLeftIcon.vue";

const carousel = ref(null);
const props = defineProps(["data"]);
const headline = ref(props.data.headline);
const mockupShowcase = ref(props.data.mockupShowcase);

const slideInitiated = ref(false);
const slidesToShow = ref(1);

const breakpoints = ref({
  700: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
});

const updateSlidesToShow = () => {
  const windowWidth = window.innerWidth;

  for (const key in breakpoints.value) {
    const breakpointWidth = parseInt(key);
    if (breakpointWidth <= windowWidth) {
      slidesToShow.value = breakpoints.value[key].itemsToShow;
    }
  }
};

const shouldShowArrow = () =>{
  if (carousel && carousel.value.data.currentSlide <= 3) {
    return true;
  } else {
    return false;
  }
}

const handleInit = () => {
  slideInitiated.value = true;
}

onMounted(() => {
  updateSlidesToShow();
  window.addEventListener("resize", updateSlidesToShow);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSlidesToShow);
});
</script>

<style scoped>
.carousel__pagination {
  @apply mt-12;
  @apply lg:hidden;
}
</style>
