export function getTeamQuery() {
  return gql`
query getTeam {
  team(where: {status: PUBLISH}) {
    edges {
      node {
        id,
        title,
        teamMeta {
          bio,
          role
        }
        featuredImage {
          node {
            altText
            srcSet,
            sizes
            sourceUrl
          }
        }
      }
    }
  }
}
`;
}
