<template>
  <hr class="my-4 md:my-8 xl:my-16 border-grey">
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
