import { Component } from "vue";
import ContentWithImage  from "~/components/pages/blocks/ContentWithImage.vue";
import LatestResources from "~/components/pages/blocks/LatestResources.vue";
import HeadlineAndText from "~/components/pages/blocks/HeadlineAndText.vue";
import PageLinks from "~/components/pages/blocks/PageLinks.vue";
import PageLinkSlider from "~/components/pages/blocks/PageLinkSlider.vue";
import HeadlineWithButton from "~/components/pages/blocks/HeadlineWithButton.vue";
import Seperator from "~/components/pages/blocks/Seperator.vue";
import DividerImages from "~/components/pages/blocks/DividerImages.vue";
import Callout from "~/components/pages/blocks/Callout.vue";
import MockupShowcase from "~/components/pages/blocks/MockupShowcase.vue";
import ContactForm from "~/components/pages/forms/ContactForm.vue";
import FAQs from "~/components/pages/blocks/FAQs.vue";
import OurTeam from "~/components/pages/blocks/OurTeam.vue";
import RandomQuote from "~/components/pages/blocks/RandomQuote.vue";
import PullOutText from "~/components/pages/blocks/PullOutText.vue";

const componentMapping: { [key: string]: Component } = {
  'Page_Pagemeta_PageComponents_ContentWithImage': ContentWithImage,
  'Page_Pagemeta_PageComponents_LatestResources': LatestResources,
  'Page_Pagemeta_PageComponents_HeadlineText': HeadlineAndText,
  'Page_Pagemeta_PageComponents_PageLinks': PageLinks,
  'Page_Pagemeta_PageComponents_PageLinkSlider': PageLinkSlider,
  'Page_Pagemeta_PageComponents_HeadlineWithButton' : HeadlineWithButton,
  'Page_Pagemeta_PageComponents_Seperator': Seperator,
  'Page_Pagemeta_PageComponents_DividerImages' : DividerImages,
  'Page_Pagemeta_PageComponents_Callout': Callout,
  'Page_Pagemeta_PageComponents_PhoneMockupShowcase': MockupShowcase,
  'Page_Pagemeta_PageComponents_ContactForm': ContactForm,
  'Page_Pagemeta_PageComponents_Faqs': FAQs,
  'Page_Pagemeta_PageComponents_OurTeam' : OurTeam,
  'Page_Pagemeta_PageComponents_RandomQuote' : RandomQuote,
  'Page_Pagemeta_PageComponents_PulloutText' : PullOutText
  // Add more mappings as needed
};

export const getComponentFromName = (name: string) => {
  if (componentMapping[name]) {
    return componentMapping[name];
  }
  return 'ErrorComponent';
};
export default componentMapping;
