<template>
  <section class="my-4 md:my-12" v-if="resources.length > 0">
    <div class="px-8 md:px-12 lg:px-16 xl:px-24" :class="!props.data.showFeaturedResource ? 'bg-gradient-to-b from-white via-primary/10 to-white' : ''">
      <div class="page-container">
        <h2 v-if="props.data.showLatestTitle">Latest</h2>
        <div class="flex flex-col md:flex-row flex-wrap gap-[3%]">
          <template v-for="(resource, index) in resources">
            <div class="w-full" :class="isFeaturedPost(index) ? 'w-full' : 'md:w-[calc(33.333%_-_2%)]'">
              <ResourceCard :resource="resource" :is-featured="isFeaturedPost(index)"/>
            </div>
          </template>
        </div>
        <div v-if="resources.length > 0 && props.data.showViewMoreButton" class="flex justify-center my-8 md:my-12">
          <PageLinkButton theme="grey" to="/resources">View More</PageLinkButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { getPostsQuery } from "~/graphql/queries/getPosts";
import PageLinkButton from "~/components/global/Buttons/PageLinkButton.vue";
import ResourceCard from "~/components/pages/blocks/Resources/ResourceCard.vue";

const props = defineProps(["data"]);

const resources = ref([]);

const query = getPostsQuery(props.data.count, props.data.showFeaturedResource);

const { data } = await useAsyncQuery(query);

if (data.value?.posts?.edges) {
  resources.value = data.value?.posts?.edges.map((item: any) => {
    return item.node;
  });
}

const isFeaturedPost = (index: number) => {
  return index === 0 && props.data.showFeaturedResource;
}
</script>

<style scoped>

</style>
