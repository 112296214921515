<template>
  <div class="px-8 md:px-12 lg:px-16 xl:px-24 py-12">
    <div class="page-container">
      <div class="bg-primary p-8 md:p-12 rounded-xl flex flex-col sm:flex-row relative"
           :class="[
            'bg-primary p-12 rounded-xl flex flex-col sm:flex-row relative',
            !illustration?.sourceUrl && 'bg-custom', // Add your custom class here
          ]"
      >
        <div class="flex gap-6" :class="!illustration?.sourceUrl ? 'w-full flex-col items-start' : 'md:w-3/6 flex-col items-baseline'">
          <div>
            <h2 class="text-4xl md:!text-5xl text-white !mb-8" v-if="header.length > 0" v-html="header"/>
            <p class="!text-2xl !text-secondary !mb-0">{{subHeader}}</p>
            <p v-if="text && text.length > 0" class="!text-white !mb-0">{{text}}</p>
          </div>
          <PageLinkButton :link-object="button.url" theme="outline-white">
            {{button.title }}
          </PageLinkButton>
        </div>
        <div v-if="illustration?.sourceUrl" class="md:w-3/6 md:absolute right-0 top-0 bottom-0">
          <img class="h-full w-auto pt-8" :src="illustration.sourceUrl" :alt="illustration.altText"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageLinkButton from "~/components/global/Buttons/PageLinkButton.vue";

const props = defineProps(["data"]);

const header = ref(props.data.header);
const subHeader = ref(props.data.subHeader);
const text = ref(props.data.text);
const illustration = ref(props.data.illustration);

const button = ref(props.data.button);

</script>

<style scoped>
.bg-custom {
  background-image: url('~/assets/img/blob.svg');
  background-repeat: no-repeat;
  background-position: right 40% top 0;
  background-size: 700px;
}

@media (min-width: 992px) {
  .bg-custom {
    background-position: right -70% top 56%;
  }
}

@media (min-width: 1200px) {
  .bg-custom {
    background-position: right -6% top 50%;
  }
}
</style>
