<template>
  <div class="page-section lg:mb-20 xl:mb-32 lg:px-16 xl:px-24 last:border-b last:border-grey">
    <div class="page-container">
      <div class="bg-white rounded-xl p-8 md:p-16">
        <div class="flex flex-col md:flex-row gap-12">
          <div v-if="headline || text" class="w-full md:w-3/6">
            <div class="pr-0 md:pr-12 h-full flex flex-col justify-between">
              <div>
                <h3>{{headline}}</h3>
                <div v-html="text"/>
              </div>
              <div v-if="image.sourceUrl">
                <img :src="image.sourceUrl" alt=""/>
              </div>
            </div>
          </div>
          <div v-if="contactForm" class="w-full md:w-3/6">
            <p v-if="successMessage" class="bg-secondary/10 px-4 py-2 rounded-lg !text-secondary font-medium text-center">
                {{successMessage}}
            </p>
            <form v-else @submit="onSubmit" ref="form">
              <div v-for="field in contactForm.formFields">
                <div class="field-group flex flex-col">
                  <label :for="field.name" v-if="field.type !== 'submit'">{{field.labels}}</label>
                  <template v-if="field.type === 'textarea'">
                    <Field v-slot="{ field }" :name="field.name">
                      <textarea v-bind="field" rows="10"  class="alt"/>
                    </Field>
                  </template>
                  <template v-else-if="field.type === 'submit'">
                    <Button type="submit" :is-loading="isProcessing">{{ field.labels }}</Button>
                  </template>
                  <template v-else>
                    <Field :name="field.name" :type="field.type" class="alt"/>
                  </template>
                  <ErrorMessage :name="field.name" />
                </div>
              </div>
            </form>
            <div v-if="errorMessage" class="text-red text-sm">
              {{errorMessage}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { getContactFormQuery } from "~/graphql/queries/getContactForm";
  import { ErrorMessage, Field } from "vee-validate";
  import { useDefaultForm } from "~/composables/useDefaultForm";
  import { useContactForm } from "~/composables/useContactForm";
  import Button from "~/components/global/Buttons/Button.vue";
  import { useRecaptchaToken } from "~/composables/useRecaptchaToken";
  const { recaptcha } = useRecaptchaToken();

  const props = defineProps(['data']);
  const headline = ref(props.data.headline);
  const text = ref(props.data.text);
  const image = ref(props.data.image);

  const contactForm = ref(null);

  const { form, isProcessing, onInvalidSubmit, successMessage, errorMessage } = useDefaultForm();

  const { values, errors, handleSubmit } = useContactForm();

  const query = getContactFormQuery(props.data.formId);

  const { data } = await useAsyncQuery(query);

  if (data.value?.getContactForm) {
    contactForm.value = data.value.getContactForm;
  }

  const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

  async function onSuccess() {
    const config = useRuntimeConfig();
    const { $api } = useNuxtApp();

    const apiEndPoint = `${config.public.cmsServerUrl}/wp-json/contact-form-7/v1/contact-forms/${props.data.formId}/feedback`;

    try {
      const token = await recaptcha() ?? '';

      errorMessage.value = '';

      isProcessing.value = true;

      const formData = new FormData();

      // Convert the values object to FormData
      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          formData.append(key, values[key]);
        }
      }

      const recaptchaBody = {
        "_wpcf7_recaptcha_response": token,
        "wpcf7_recaptcha_response": token,
        "recaptcha_response": token,
        "recaptcha": token,
        "token": token
      };

      for (const key in recaptchaBody) {
        if (Object.prototype.hasOwnProperty.call(recaptchaBody, key)) {
          formData.append(key, recaptchaBody[key]);
        }
      }

      const response = await $api.post(apiEndPoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status && response.data.message) {
        if (response.data.status === 'mail_sent') {
            successMessage.value = response.data.message;
        } else {
            errorMessage.value = response.data.message;
        }
      }

    } catch (error: any) {
      if (error?.error?.response?.data?.message) {
        errorMessage.value = error.response.data.message;
      }
    } finally {
      isProcessing.value = false;
    }
  }

</script>

<style scoped>

</style>
