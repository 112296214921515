<template>
  <div class="page-section content-with-image-block lg:mb-20 xl:mb-32 lg:px-16 xl:px-24 last:border-b last:border-grey" :class="content.imageFit === 'Off Screen' ? 'overflow-x-hidden' : ''">
    <div class="page-container">
      <div class="flex flex-col md:flex-row justify-between" :class="getAlignClass(content.align)">
        <div class="w-full pt-12 mb-8 px-8 md:px-12" :class="content.style === 'Large' ? 'xl:w-2/4' : 'lg:px-0 md:w-6/12'" >
          <Tag class="" v-if="content.tag">{{ content.tag }}</Tag>

          <h2 v-if="content.style === 'Large'" class="text-4xl mb-8 mt-6">{{ content.headline }}</h2>
          <h3 v-else>{{ content.headline }}</h3>
          <div v-html="content.text" class="text-lg text-grey-300"></div>

          <div v-if="content.primaryLink || content.secondaryLink" class="mt-14 flex gap-4">
            <PageLinkButton v-if="content.primaryLink" :link-object="content.primaryLink.url" theme="grey">
              {{ content.primaryLink.title }}
            </PageLinkButton>

            <PageLinkButton v-if="content.secondaryLink" :link-object="content.secondaryLink.url" theme="blank">
              {{ content.secondaryLink.title }}
            </PageLinkButton>
          </div>
        </div>
        <div class="w-full" :class="getImageWrapperClass(content)">
          <div class="relative" :class="images.length > 1 ? 'mb-8 md:mb-[10rem]' : ''">
            <div v-for="(image, index) in images" class="rounded-xl overflow-hidden border-4 border-white h-0"  :class="[
                'rounded-xl overflow-hidden border-4 border-white h-0',
                content.imageFit === 'Off Screen' ? '!w-[150%]' : '',
                index > 0 && index === images.length - 1 ? 'w-6/12 pb-[60%] absolute -right-[1rem] md:-right-[3rem] -bottom-[5rem] md:-bottom-[10rem]' : 'relative pb-[97.97%] w-full'
              ]">
              <img
                :src="image.sourceUrl"
                :alt="image.altText"
                class="absolute inset-0 w-full h-full"
                :class="['Contain', 'Off Screen'].includes(content.imageFit) ? 'object-contain' : 'object-cover'"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Tag from "~/components/global/Tag.vue";
import PageLinkButton from "~/components/global/Buttons/PageLinkButton.vue";

const props = defineProps(["data"]);

const content = ref(props.data.content);
const images = ref(props.data.images);

const getAlignClass = (align: string) => {
  switch (align) {
    case "Left" :
      return "xl:!flex-row-reverse";
    case "Right" :
      return "xl:!flex-row";
  }
};

const getImageWrapperClass = (content: any) => {
  const style = content.style;
  const imageFit = content.imageFit;

  if (imageFit == 'Off Screen') {
    return 'md:w-6/12'
  }

  if (style === 'Large') {
      return 'px-8 md:px-12 xl:w-2/4';
  } else {
    return 'px-8 md:w-1/3';
  }
}

</script>

<style scoped>

</style>
