<template>
  <div class="px-8 md:px-12 lg:px-16 xl:px-24 mb-8 xl:mb-24">
    <div class="page-container">
      <div class="flex flex-col md:flex-row justify-between items-start">
        <h3 class="text-xl font-medium md:w-6/12 mb-4">{{ headline }}</h3>
        <PageLinkButton theme="grey" :link-object="button.url">{{ button.title }}</PageLinkButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageLinkButton from "~/components/global/Buttons/PageLinkButton.vue";

const props = defineProps(["data"]);

const headline = ref(props.data.headline);
const button = ref(props.data.button);

</script>

<style scoped>

</style>
