// initialize a instance
import { useReCaptcha } from "vue-recaptcha-v3";

export function useRecaptchaToken() {

  const recaptchaInstance = useReCaptcha();

  const recaptcha = async () => {

    console.log(recaptchaInstance);

    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded();

    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha('login');

    return token;
  };

  return {
    recaptcha,
  };
}
